<script>
import CButton from '../components/CButton.vue'
import CFormCheckbox from '../components/CFormCheckbox.vue'
import CFormColumns from '../components/CFormColumns.vue'
import CFormFieldset from '../components/CFormFieldset.vue'
import CFormRadio from '../components/CFormRadio.vue'
import CFormText from '../components/CFormText.vue'
import CFormCheck from '../components/CFormCheck.vue'
import CSelect from '../components/CSelect.vue'
import CBranchSelect from '../components/CBranchSelect.vue'
import CTitle from '../components/CTitle.vue'

export default {
  components: {
    CButton,
    CFormCheckbox,
    CFormCheck,
    CFormColumns,
    CFormFieldset,
    CFormRadio,
    CFormText,
    CSelect,
    CBranchSelect,
    CTitle
  },

  beforeRouteLeave (to, from, next) {
    // Automatically save if the user leaves the form.
    // The check for the form is necessary because we might leave the route
    // before it has been `mounted` (see `created` hook).
    if (this.$refs.form) {
      this.save()
    }

    next()
  },

  data () {
    return {
      autosave: null,
      errorMsg: 'Bitte korrigieren Sie alle Felder, die mit einer Fehlermeldung markiert sind.'
    }
  },

  computed: {
    errors () {
      return this.$store.state.errors.fields
    }
  },

  created () {
    // Restore data from localStorage and store.
    const persistedFields = JSON.parse(localStorage.getItem(this.id))
    const storedFields = this.$store.state.steps[this.step]?.fields

    this.fields = {
      ...this.fields,
      ...persistedFields,
      ...storedFields
    }

    // Check previous steps for completion and go to the first known completed step.
    for (let i = 0; i < this.step; i++) {
      if (!this.$store.state.steps[i]?.completed) {
        this.$router.push({ name: `request-step-${i}` })
        break
      }
    }
  },

  mounted () {
    // Save form to check initial form validity and update store.
    this.save()

    // Autosave form every ten seconds.
    this.autosave = setInterval(() => { this.save() }, 10000)
  },

  destroyed () {
    // We don't want rogue autosavers to roam the realm.
    clearInterval(this.autosave)
  },

  methods: {
    save () {
      this.$store.commit('step', {
        step: this.step,
        id: this.id,
        fields: { ...this.fields },
        completed: this.$refs.form.checkValidity()
      })
    },
    previous () {
      // Saving is done in the `beforeRouteLeave` hook.
      this.$router.push({ name: `request-step-${this.step - 1}` })
    },
    next () {
      const form = this.$refs.form

      // Check and report any form errors to the user.
      // Only proceed to the next step if the form is valid.
      // Internet Explorer doesn't support the `reportValidity` method on forms,
      // so we first have to check whether the methods exists.
      if (form.reportValidity && !form.reportValidity()) {
        return
      }

      // Saving is done in the `beforeRouteLeave` hook.
      this.$router.push({ name: `request-step-${this.step + 1}` })
    }
  }
}
</script>
